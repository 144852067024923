import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Button, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Menu, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { useAuth } from '../business/authProvider';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import VacationSheet from './vacationSheet';
import Users from './pages/users';
import Notes from './pages/notes'
import UpdatePassword from '../components/updatePassword';
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  offset: theme.mixins.toolbar,
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    padding: 12

  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));
const AppTopBar = (props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  let auth = useAuth();
  const onLogout = () => auth.signout()
  return (
    <>
      <AppBar position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
        <Toolbar>
          <Button color='inherit' disableRipple onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.hide)}>
            <MenuIcon />
            <Typography variant="body2" className={classes.title}>Menu</Typography>
          </Button>
          <Typography variant="h6" className={classes.title}>Vacation Planner</Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={clsx(classes.drawer, 'app-drawer')}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          {/*<Typography variant="h6" className={classes.title}>Vacation Planner</Typography>*/}
          <Button disableRipple onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            <Typography variant="body2" className={classes.title}>Hide Menu</Typography>
          </Button>
        </div>
        <Divider />
        <List>
          <ListItem button component={Link} to="/planner">
            <ListItemText primary={'Planner'} />
          </ListItem>
          <ListItem button component={Link} to="/notes">
            <ListItemText primary={'Notes'} />
          </ListItem>
          {auth.permissions.user?.list ? <ListItem button component={Link} to="/users">
            <ListItemText primary={'Users'} />
          </ListItem> : null
          }
          <ListItem button component={Link} to="/update-password" >
            <ListItemText primary={'Update Password'} />
          </ListItem>
          <ListItem button onClick={onLogout}>
            <ListItemText primary={'Logout'} />
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <Route path={'/planner'} component={VacationSheet} />
          <Route path={'/users'} component={Users} />
          <Route path={'/notes'} component={Notes} />
          <Route path={'/update-password'} render={props => <UpdatePassword />} />
          <Route path="*">
            <Redirect to="/planner" />
          </Route>
        </Switch>
      </main>
    </>
  )
}
export default AppTopBar